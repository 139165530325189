import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';

// https://github.com/ionic-team/ionic-storage#readme

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private _storage: Promise<Storage>

  constructor(private storage: Storage) {
    this._storage = storage.create();
  }

  set(key: string, value: any): Promise<void> {
    return this._storage.then(s => {
      if (s === null) {
        console.log("storage is null");
      } else {
        s.set(key, value);
      }
    });
  }

  get(key: string): Promise<any> {
    return this._storage.then(s => {
      if (s === null) {
        console.log("storage is null");
      } else {
        return s.get(key)
      }
      return null;
    });
  }

  has(key: string): Promise<boolean> {
    return this._storage.then(s => {
      if (s === null) {
        console.log("storage is null");
      } else {
        return s.get(key) !== null;
      }
      return null;
    });
  }

  remove(key: string): Promise<void> {
    return this._storage.then(s => {
      if (s === null) {
        console.log("storage is null");
      } else {
        s.remove(key);
      }
    });
  }

  clear(): Promise<void> {
    return this._storage.then(s => {
      if (s === null) {
        console.log("storage is null");
      } else {
        s.clear();
      }
    });
  }

  keys(): Promise<string[]> {
    return this._storage.then(s => {
      if (s === null) {
        console.log("storage is null");
      } else {
        return s.keys();
      }
    });
  }

  length(): Promise<number> {
    return this._storage.then(s => {
      if (s === null) {
        console.log("storage is null");
      } else {
        return s.length();
      }
      return null;
    });
  }

}
