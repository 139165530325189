import { Component } from '@angular/core';

import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-gdpr-modal',
  templateUrl: 'gpdr-modal.html',
})
export class GdprModal {

  constructor(private modalCtrl: ModalController) {}

  cancel() {
    return this.modalCtrl.dismiss(false);
  }

  confirm() {
    return this.modalCtrl.dismiss(true);
  }
}
