import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/services/login.service';
import { Router, ActivatedRoute, convertToParamMap } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { UtilityService } from 'src/services/utility.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  username: string = "";
  password: string = "";
  password2: string = "";
  remember: boolean = false;

  resetPassword: boolean = false;
  setPassword: boolean = false;
  forgotPassword: boolean = false;
  resetGuid: string;

  emailValidator = new FormControl('', [Validators.required, Validators.email]);
  passwordValidator = new FormControl('', [Validators.required, Validators.minLength(4)]);

  constructor(public loginService: LoginService, private route: ActivatedRoute, private router: Router, private us: UtilityService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const paramMap = convertToParamMap(params);
      if (paramMap.has("mail"))
        this.username = paramMap.get("mail");
      if (paramMap.has("guid"))
        this.resetGuid = paramMap.get("guid");

      this.resetPassword = paramMap.has("reset");
      this.setPassword = paramMap.has("set");
      this.forgotPassword = paramMap.has("forgot");
      if (this.forgotPassword && this.resetPassword) this.resetPassword = !this.resetPassword;
    });
  }

  loginKeyDown(event) {
    console.log("key pressed");
    if (event.key === "Enter") {
      this.login();
    }
  }

  login() {
    if (!this.emailValidator.valid)
      this.us.toastMessage("please enter a valid email address");
    else if(!this.passwordValidator.valid) {
      this.us.toastMessage("please enter your password");
    } else {
      this.loginService.validateGDPR(this.username, this.password, this.remember);
    }
  }

  resetKeyDown(event) {
    if (event.key === "Enter")
      this.reset();
  }

  forgotKeyDown(event) {
    if (event.key === "Enter")
      this.sendForgotMail();
  }

  reset() {
    if (!this.passwordValidator.valid) {
      this.us.toastMessage("Please enter a valid password (at least 4 characters long)");
    } else if (this.password != this.password2) {
      this.us.toastMessage("Passwords are not matching!");
    } else {
      this.loginService.reset(this.username, this.password, this.resetGuid).subscribe({
        next: (resp) => { if (resp) { this.resetPassword = false; this.forgotPassword = false; this.setPassword = false; } }
      });
    }
  }

  sendForgotMail() {
    if (!this.emailValidator.valid) this.us.toastMessage("Please enter a valid email address!");
    else this.loginService.sendForgotMail(this.username);
  }
}
