import { Injectable } from '@angular/core';
import {BehaviorSubject, first, Subject} from 'rxjs';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';
import { UtilityService } from './utility.service';
import {ModalController} from "@ionic/angular";
import {GdprModal} from "../app/login/gdpr/gdpr-modal";

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private _user = new BehaviorSubject({user: "", pw: ""});
  public user$ = this._user.asObservable();
  acceptedGDPR: boolean = true;

  constructor(private storage: StorageService, private api: ApiService, private us: UtilityService, private modalCtrl: ModalController) {
  }

  //this method tries to log in with stored credentials
  public async storedCredentials() {
    console.log("using stored credentials");
    const user = await this.storage.get("username");
    const password = await this.storage.get("password");
    if (user != null && password != null)
      this.login(user, password);
  }


  // this method tries to log in at the remote with the provided username and password
  // if username and password were correct the logged-in user can be retrieved through the user$ observable
  public login(username: string, password: string, remember: boolean = false) {
    console.log("trying to log in...");
    this.api.loadProjects(username, password).subscribe({
      next: (resp) => {
        if (resp?.ok) {

          this.api.loadTenantNames(resp.data.join(";")).subscribe({
            next: (names) => {if (names?.ok) this.us.projectIds = names.data;},
            error: (err) => this.us.toastMessage(err)
          });

          this.api.loadProjectInfo(resp.data[0]).subscribe({
            next: (resp2) => {
              if (resp2) {
                this.api.loadRooms(resp.data[0]).subscribe({
                  next: (resp3) => {
                    if (resp3) {
                      let d = resp2;
                      d.pguid = resp.data[0];
                      d.tenantRooms = resp3;

                      this.us.projects.set(resp.data[0], d);

                      if (remember) {
                        this.storage.set("username", username);
                        this.storage.set("password", password);
                      }

                      this.us.projectGuids.next(resp.data);
                      this._user.next({user: username, pw: password});
                    }
                  }, error: (err) => this.us.toastMessage(err)
                });
              }
            }, error: (err) => this.us.toastMessage(err)
          });

        }
      }, error: (err) => this.us.toastMessage(err)
    });
  }

  public reset(username: string, password: string, guid: string) {
    let ret = new Subject<boolean>();
    this.api.setPasswordCall(username, password, guid).subscribe({next: (resp) => {
        if (resp?.ok) {
          this.us.toastMessage("Successfully set new password!");
          ret.next(true);
        }
      }, error: (err) => this.us.toastMessage(err)});
    return ret.pipe(first());
  }

  public sendForgotMail(mail: string) {
    this.api.sendPasswordForgotMail(mail).subscribe({next: (resp) => {
        if (resp?.ok) this.us.toastMessage(resp.msg);
      }, error: (err) => this.us.toastMessage(err)});
  }

  public logout() {
    this._user.next({user: "", pw: ""});
    this.storage.remove("username");
    this.storage.remove("password");
  }

  public async validateGDPR(username: string, password: string, remember: boolean = false) {
    this.api.loadGDPR(username, password).subscribe({next:  async (respGDPR) => {
        if (respGDPR?.ok && respGDPR.accepted) this.login(username, password, remember);
        else {
          const modal = await this.modalCtrl.create({
            component: GdprModal,
          });
          modal.present();

          let data = await modal.onWillDismiss();

          if (data.data) {
            this.api.setGDPR(true).subscribe(
              () => {
                this.login(username, password, remember);
                this.acceptedGDPR = true;
              },
              (error) => this.us.toastMessage(error)
            )
          }
          else this.acceptedGDPR = false;
        }
      }, error: (err) => this.us.toastMessage(err)});

  }
}
