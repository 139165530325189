import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { deAT as de_date } from "date-fns/locale";
import { addDays, addMinutes, addMonths, differenceInDays, endOfMonth, format, startOfDay, startOfMonth, startOfWeek, startOfYear} from "date-fns";

export enum TIMEMODE {
  DAY = 0,
  WEEK = -10,
  MONTH = -20,
  YEAR = -30
}

export class Period {
  name: string;
  timemode: TIMEMODE

  constructor(name: string, timemode: TIMEMODE) {
    this.name = name;
    this.timemode = timemode;
  }
}

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  public selectedProjSbj: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public premium: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public projectGuids: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  public projects: Map<string, any> = new Map<string, any>();
  public projectIds: Map<string, string> = new Map<string, string>();

  constructor(private toast: ToastController) { }

  static timeMode: TIMEMODE;

  async toastMessage(msg: string) {
    this.toast.create({
      message: msg,
      duration: 3000
    }).then((t) => t.present());
  }

  static getXLabels(startUnix: number, endUnix: number, timeMode: TIMEMODE): string[] {
    this.timeMode = timeMode;
    return this.SetUpTimeScale(startUnix, timeMode);
  }

  private static _ToFix2(val: number): string {
    if (val < 10) {
      return "0" + val.toString();
    }
    return val.toString();
  }

  static SetUpTimeScale(StartDate: number, timeMode: TIMEMODE): Array<any> {
    const labels = new Array<string>();
    let formatString: string;
    switch (timeMode) { // declare labels for y axis
        case TIMEMODE.DAY:
            let time = startOfDay(Date.now());
            formatString = "p"
            for (let i = 0; i < 96; i++) 
                labels.push(format(addMinutes(time, 15 * i), formatString, {locale: de_date}));
            break;
        case TIMEMODE.WEEK:
            let weekday = startOfWeek(Date.now(), {locale: de_date});
            formatString = "EEEEEE";
            for (let i = 0; i < 7; i++)
                labels.push(format(addDays(weekday, i), formatString, {locale: de_date}));
            break;
        case TIMEMODE.MONTH:
            formatString = "dd"
            let dateMonth = StartDate;
            let firstOfMonth = startOfMonth(dateMonth);
            let lastOfMonth = endOfMonth(dateMonth);
            while(differenceInDays(firstOfMonth, lastOfMonth) < 0) {
                labels.push(format(firstOfMonth, formatString, {locale: de_date}));
                firstOfMonth = addDays(firstOfMonth, 1);
            }
            labels.push(format(firstOfMonth, formatString, {locale: de_date}));
            break;
        case TIMEMODE.YEAR:
            let dateYear = startOfYear(Date.now());
            formatString = "LLL"
            for (let i = 0; i < 12; i++)
                labels.push(format(addMonths(dateYear, i), formatString, {locale: de_date}))
            break;
    }
    return labels;
  }

}
